<template>
    <table-layout :title="t('COURSE_APPROVALS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
       <template #table>
            <cr-table :items="page.items" :headers="headers" actionable :loading="isLoading" @suggestedNumberOfItems="onSuggestedNumberOfItems" class="cr-pl6 cr-pr6 cr-pt6 cr-pb2" data-testing="course-approvals-table">
                <template v-slot:action="{item}">
                    <cr-table-action-item :to="{ name: Route.Course.name, params: { courseId: item.id } }" icon="bi-eye">
                        {{ t('COURSE_APPROVALS_VIEW') }}
                    </cr-table-action-item>
                    <cr-table-action-item :item="item" @click="onApproveCourseClicked" icon="bi-check">
                        {{ t('COURSE_APPROVALS_APPROVE') }}
                    </cr-table-action-item>
                    <cr-table-action-item :item="item" @click="onRejectCourseClicked" icon="bi-x">
                        {{ t('COURSE_APPROVALS_REJECT') }}
                    </cr-table-action-item>
                </template>
            </cr-table>
       </template>

       <template #pagination>
           <cr-pagination :previous="!!page.prevPageToken" :first="true" :next="!!page.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading" data-testing="course-approvals-table-pagination"/>
       </template>
    </table-layout>

    <course-approved-dialog v-model="showCourseApprovedDialog" @confirm="onCourseApprovedCompletedConfirm" />
    <course-rejected-dialog v-model="showCourseRejectedDialog" @confirm="onCourseRejectedCompletedConfirm" />
</template>

<script setup lang="ts">
import { BreadcrumbItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useArtificialPaging } from '../../composables/useArtificiailPaging';
import { useCalendar } from '../../composables/useCalendar';
import { useCourseApprovals } from '../../composables/useCourseApprovals';
import Route from '../../routers/route';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import { useCourseStore } from '../../stores/courseStore';
import TableLayout from '../layouts/TableLayout.vue';
import CourseApprovedDialog from './dialogs/CourseApprovedDialog.vue';
import CourseRejectedDialog from './dialogs/CourseRejectedDialog.vue';
import { useOrganizationStore } from '../../stores/organizationStore';

const { t } = useI18n();
const courseStore = useCourseStore();
const businessUnitStore = useBusinessUnitStore()
const { toHumanDate } = useCalendar()

const breadcrumbs = [new BreadcrumbItem(Route.CourseApprovals)];

const {
    onApproveCourseClicked,
    onRejectCourseClicked,
    showCourseApprovedDialog,
    showCourseRejectedDialog,
} = useCourseApprovals()
const onCourseApprovedCompletedConfirm = courseStore.fetchPendingCourses;
const onCourseRejectedCompletedConfirm = courseStore.fetchPendingCourses;

const headers = [
    new TableHeaderItem({ text: 'Name', key: 'name' }),
    new TableHeaderItem({ text: 'Organization', key: 'organizationName' }),
    new TableHeaderItem({ text: 'Business Unit', key: 'businessUnitId', formatter: (id) => businessUnitStore.businessUnitsById.get(id)?.name ?? '' }),
    new TableHeaderItem({ text: 'Creation', key: 'startTime', formatter: (timestamp) => toHumanDate(timestamp) }),
    new TableHeaderItem({ text: 'Expiration', key: 'endTime', formatter: (timestamp) => toHumanDate(timestamp) }),
];

const { pendingCourses } = storeToRefs(courseStore)
const {
    page,
    onSuggestedNumberOfItems,
    onLoadPreviousPage,
    onLoadNextPage,
    onLoadFirstPage,
} = useArtificialPaging(pendingCourses);

const isLoading = ref(false);
const refresh = async () => 
{
    isLoading.value = true;
    try
    {
        await Promise.all([
            businessUnitStore.fetchBusinessUnits(),
            courseStore.fetchPendingCourses(),
            useOrganizationStore().fetchOrganizationNameAndLogo()
        ]);
    }
    finally
    {
        isLoading.value = false;
    }
}
onMounted(async ()=> await refresh());
</script>
