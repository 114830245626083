<template>
    <table-layout :title="t('ORGANIZATION_USAGES_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
        <template #filter>
            <table-filter-section>
                <table-filter-section-item>
                    <between-date-filter :from-date="filter.from" :to-date="filter.to" @update:from-date="onFromFilterUpdated" @update:to-date="onToFilterUpdated" :label="t('USAGES_FILTER_DATE_LABEL')" data-testing="organization-usages-date-filter"/>
                </table-filter-section-item>
                <table-filter-section-item>
                    <autocomplete-filter v-model="filter.category" :items="Category" icon="bi-diagram-3" :label="t('USAGES_FILTER_CATEGORY_LABEL')" :disabled="isLoading" data-testing="organization-usages-category-filter"/>
                </table-filter-section-item>
                <table-filter-section-item>
                    <autocomplete-filter v-model="filter.categoryId" :items="CloudUsageCategory" icon="bi-diagram-3" :label="t('USAGES_FILTER_SUBCATEGORY_LABEL')" :disabled="isSubcategoryFilterDisabled" data-testing="organization-usages-subcategory-filter"/>
                </table-filter-section-item>
            </table-filter-section>
       </template>

       <template #table>
            <cr-table :items="page?.items" :headers="headers" @sort="onSort" :loading="isLoading" @suggestedNumberOfItems="onSuggestedNumberOfItems" class="cr-pl6 cr-pr6 cr-pt6 cr-pb2" data-testing="organization-usages-table" />
       </template>

       <template #pagination>
           <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading" data-testing="organization-usages-table-pagination"/>
       </template>
   </table-layout>
</template>


<script setup lang="ts">
import { Category, CloudUsageCategory, IUsageFilter, UsageFilter } from '@cyber-range/cyber-range-api-usage-client';
import { BreadcrumbItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watchSyncEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCalendar } from '../../composables/useCalendar';
import { useQueryFilter } from '../../composables/useQueryFilter';
import { useTableLayoutPagination } from '../../composables/useTableLayoutPagination';
import Route from '../../routers/route';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useUsagesStore } from '../../stores/usagesStore';
import AutocompleteFilter from '../filters/AutocompleteFilter.vue';
import BetweenDateFilter from '../filters/BetweenDateFilter.vue';
import TableLayout from '../layouts/TableLayout.vue';
import TableFilterSection from '../layouts/sections/TableFilterSection.vue';
import TableFilterSectionItem from '../layouts/sections/TableFilterSectionItem.vue';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { toYYYYMMDD, getStartOfMonth, getEndOfMonth } = useCalendar();

const breadcrumbs = computed(()=> [
    new BreadcrumbItem(Route.Organizations),
    new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}}),
    new BreadcrumbItem({...Route.OrganizationUsages, params: {organizationId: props.organizationId}})
]);

const props = defineProps<
{
   organizationId: string
}>(); 
const organization = ref<IOrganization>();
onMounted(async () => 
{
    organization.value = await useOrganizationStore().getOrganization(props.organizationId);
    await useOrganizationStore().fetchOrganizationNameAndLogo(organization.value ? [organization.value]: undefined);
})

// Filters
const startOfMonth = toYYYYMMDD(getStartOfMonth());
const endOfMonth = toYYYYMMDD(getEndOfMonth())

const defaultFilter: Partial<IUsageFilter> = {
    organizationId: props.organizationId,
    from: startOfMonth,
    to: endOfMonth,
}
const onFromFilterUpdated = (value?:string) => filter.from = value || startOfMonth;
const onToFilterUpdated = (value?:string) => filter.to = value || endOfMonth;
const isSubcategoryFilterDisabled = computed(() => filter.category !== Category.CloudUsage || isLoading.value);

// Pagination
const {
    filter,
    onLoadFirstPage,
    onLoadNextPage,
    onLoadPreviousPage,
    onSort,
    onSuggestedNumberOfItems,
    page
} = useTableLayoutPagination(useQueryFilter(UsageFilter, defaultFilter), useUsagesStore().list, { clearTokenKeys: ['category', 'from', 'to']})

watchSyncEffect(() =>
{
    if (filter.category !== Category.CloudUsage)
    {
        filter.categoryId &&= undefined;
    }
});

// Table
const headers = [
    new TableHeaderItem({ text: 'Category', key: 'category' }),
    new TableHeaderItem({ text: 'Category Name', key: 'categoryName' }),
    new TableHeaderItem({ text: 'Category Id', key: 'categoryId' }),
    new TableHeaderItem({ text: 'Date', key: 'date', sortable: true, sortOrder: filter?.sortOrder }),
    new TableHeaderItem({ text: 'Quantity', key: 'unit' }),
];
</script>
