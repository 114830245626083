export default {
    en: {
        PROFILE_TITLE: "Profile",
        PROFILE_DETAILS: "Details",
        PROFILE_DETAILS_LAST_LOGIN_WITH: "Last login with",
        PROFILE_DETAILS_LAST_LOGIN: "Last login",
        PROFILE_DETAILS_CREATED_TIMESTAMP: "User since",
        PROFILE_DETAILS_ID: "User ID",
        PROFILE_DETAILS_TOKEN: "Token",
        PROFILE_IMPERSONATE_BUTTON_LABEL: 'Impersonate',
        PROFILE_VIEW_LOGS_BUTTON_LABEL: 'View Logs',
    }
}