export default {
    en: {
        // Exercise Group List
        EXERCISE_GROUP_LIST_TITLE: 'Exercise Groups',
        // Exercise Group Tile
        EXERCISE_GROUP_ENABLED: 'Available to Students',
        EXERCISE_GROUP_DISABLED: 'Unavailable to Students',
        EXERCISE_GROUP_START_LABEL: 'Start:',
        EXERCISE_GROUP_END_LABEL: 'End:',
        // Exercise Group Page - Top
        EXERCISE_GROUP_ABOUT_TEXT: 'About the Exercise Environment',
        EXERCISE_GROUP_AVAILABILITY: 'Availability',
        EXERCISE_GROUP_NOT_AVAILABLE: 'This exercise is not yet available.',
        EXERCISE_GROUP_EXPIRED: 'This exercise is no longer available.',
        EXERCISE_GROUP_CREATION: 'Creation',
        EXERCISE_GROUP_SESSION: 'Session',
        EXERCISE_GROUP_SESSION_PERSISTENT: 'Persistent',
        EXERCISE_GROUP_SESSION_NON_PERSISTENT: 'Non-Persistent',
        EXERCISE_GROUP_ACCESSIBILITY: 'Accessibility',
        EXERCISE_GROUP_RESOURCES: 'Resources',
        EXERCISE_GROUP_MY_ENVIRONMENT_STATUS: 'Status',
        EXERCISE_GROUP_MY_ENVIRONMENT_STATUS_ANNOUNCEMENT: 'Your exercise is now { status }.',
        EXERCISE_GROUP_INTER_TEAM_CONNECTVITY_STATUS_LABEL: 'Inter-Team Connectivity',
        EXERCISE_GROUP_INTER_TEAM_CONNECTVITY_STATUS_ANNOUNCEMENT: 'Inter-Team Connectivity is now { status }.',
        EXERCISE_GROUP_MY_ENVIRONMENT_DNS_RECORDS: 'Your Team\'s DNS Records',
        EXERCISE_GROUP_MY_ENVIRONMENT_DNS_COPY_LABEL: 'Copy DNS record for {recordName} to clipboard',

        // Exercise Group Page - Exercise Group Controls
        EXERCISE_GROUP_EDIT_BUTTON: 'Edit Environment',
        EXERCISE_GROUP_DELETE_BUTTON: 'Delete Environment',
        EXERCISE_GROUP_DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{exerciseGroupName}</b>?',
        EXERCISE_GROUP_EXERCISE_NEEDS_A_REINSTALL_MESSAGE: 'This exercise needs to be reinstalled.',
        EXERCISE_GROUP_ASK_FOR_EXERCISE_REINSTALL_ASSISTANCE_MESSAGE: 'This exercise needs to be reinstalled. Please contact your instructors or TAs for assistance.',
        // Exercise Group Creation Page
        EXERCISE_GROUP_CREATE_PAGE_TITLE: 'Create Exercise Environment',
        EXERCISE_GROUP_CREATE_FAMILY_DESCRIPTION_LABEL: 'Description',
        EXERCISE_GROUP_CREATE_BACK_BUTTON: 'Back',
        EXERCISE_GROUP_FORM_SECTION_TITLE_EXERCISE_ENVIRONMENT_INFORMATION: 'Exercise Environment Information',
        EXERCISE_GROUP_CREATE_NAME_LABEL: 'Name',
        EXERCISE_GROUP_CREATE_DESCRIPTION_LABEL: 'Description',
        EXERCISE_GROUP_CREATE_DETAILS_SECTION_LABEL_VARIATIONS: 'Variations',
        EXERCISE_GROUP_CREATE_DETAILS_SECTION_LABEL_ACCESSIBILITY: 'Accessibility',
        EXERCISE_GROUP_FORM_SECTION_TITLE_AVAILABILITY: 'Availability',
        EXERCISE_GROUP_FORM_START_LABEL: 'Start Date',
        EXERCISE_GROUP_FORM_END_LABEL: 'End Date',
        EXERCISE_GROUP_CREATE_SECTION_TITLE_PERSISTENCE: 'Environment Persistence',
        EXERCISE_GROUP_CREATE_SEARCH_CATALOG_FAMILIES_LABEL: 'Exercise Environment',
        EXERCISE_GROUP_CREATE_CATALOG_FAMILY_SUGGESTED_PARAMETER_SUFFIX: ' (Suggested)',
        EXERCISE_ENVIRONMENT_PERSISTENCE: 'Environment Persistence',
        EXERCISE_ENVIRONMENT_PERSISTENCE_HELP_TEXT: 'Learn more about persistent vs non persistent environments',
        EXERCISE_ENVIRONMENT_PERSISTENCE_REQUIRES_SUBSCRIPTION: '(This option requires a subscription)',
        EXERCISE_ENVIRONMENT_PERSISTENCE_DESCRIPTION_BOTH_OPTIONS: 'Select your environment persistence option below. There are two choices, non-persistent and persistent. Non-persistent environments are used for one user session of up to three hours and are deleted after each use. This has the benefit of giving the user a clean environment each time they login, and it greatly reduces environment cost. Persistent environments save user data and configurations between sessions. This is not needed for most student labs and environment changes for one lab may cause a future lab to fail.',
        EXERCISE_ENVIRONMENT_PERSISTENCE_DESCRIPTION_PERSISTENT_ONLY_OPTIONS: 'This environment is configured to run as a persistent environment which saves user data and configurations between sessions. This has the benefit of giving the user an ability to work on multi-step tasks that build upon one another and may require a significant amount of time to complete.',
        EXERCISE_ENVIRONMENT_PERSISTENCE_DESCRIPTION_NON_PERSISTENT_ONLY_OPTIONS: 'This environment is configured to run as a non-persistent environment which is intended for one user session of up to three hours and is deleted after each use. This has the benefit of giving the user a clean environment each time they login, and it greatly reduces environment cost.',
        EXERCISE_GROUP_CREATE_CATALOG_ENTRY_CHANGE_PROMPT: 'Changing this value will erase any changes you have made to the name or description fields.',
        EXERCISE_GROUP_CREATE_CATALOG_ENTRY_PENDING_ALERT: 'This catalog entry is in the process of being copied.',
        EXERCISE_GROUP_CREATE_CATALOG_ENTRY_ERROR_ALERT: 'A critical error occurred while copying this catalog entry. Please contact support for assistance.',
        EXERCISE_GROUP_CREATE_CATALOG_ENTRY_MISSING_REQUIRED_SUBSCRIPTION_LABEL: 'Attention',
        EXERCISE_GROUP_CREATE_CATALOG_ENTRY_MISSING_REQUIRED_SUBSCRIPTION_MESSAGE: 'This exercise environment requires a subscription.',
        EXERCISE_GROUP_CREATE_ANNOUNCEMENT_NOW_LOADING: 'Now loading environments.',
        EXERCISE_GROUP_CREATE_ANNOUNCEMENT_SHOWING_ENVIRONMENTS: 'No items found.|Now showing 1 environment.|Now showing {count} environments.',
        EXERCISE_GROUP_FORM_ADVANCED_OPTIONS: 'Advanced Options',
        EXERCISE_GROUP_FORM_AUTOUPDATE_CTF_SNAPSHOT: 'Automatically update challenges to their latest version from the Challenge Library.',
        // Exercise Group Edit Page
        EXERCISE_GROUP_EDIT_PAGE_TITLE: 'Edit Exercise Group',
        // Exercise Group Start All
        EXERCISE_GROUP_START_ALL_BUTTON: 'Start All Exercises',
        EXERCISE_GROUP_START_ALL_CONFIRM_MESSAGE: 'Are you sure you would like to start all exercises in this exercise group?',
        // Inter team connectivity
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_BUTTON: "Toggle Inter-Team Connectivity",
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_TITLE: "Toggle Inter-Team Connectivity",
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_ENABLE: 'Enable',
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_DISABLE: 'Disable',
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_DESCRIPTION: "Inter team connectivity control empowers instructors to allow or prevent network traffic between each team's virtual environment.",
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_LEARN_ABOUT: "Learn about Inter-Team Connectivity",
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_DISABLED_DESCRIPTION: "Connectivity between teams is now <b>disabled</b>.",
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_ENABLED_DESCRIPTION: "Connectivity between teams is now <b>enabled</b>.",
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_QUERY_ENABLE: "Would you like to <b>enable</b> inter-team connectivity?",
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_QUERY_DISABLE: "Would you like to <b>disable</b> inter-team connectivity?",
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_UPDATED_TITLE:'Connectivity Updated',
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_UPDATED_CONFIRM:'Close',
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_NOW_ENABLED:'Inter-team connectivity is now <b>enabled</b>. Network traffic can now travel between each team\'s virtual environment.',
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_NOW_DISABLED:'Inter-team connectivity has been <b>disabled</b>. Network traffic can no longer travel between each team\'s virtual environment.',
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_PENDING_ENABLED:'The cyber range is adjusting configurations to enable inter team connectivity.',
        EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_PENDING_DISABLED:'The cyber range is adjusting configurations to disable inter team connectivity.',
        // Export DNS
        EXERCISE_GROUP_EXPORT_DNS_BUTTON: "Export DNS Records",
    }
};
