<template>
    <table-layout :title="t('ORGANIZATION_USERS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
       <template #filter>
            <table-filter-section>
                <table-filter-section-item v-if="!onOrganizationUsersPage">
                    <organization-filter v-model="organizationId" :organizations="allowedOrganizations" required data-testing="organization-users-organization-filter"/>
                </table-filter-section-item>
                <table-filter-section-item>
                    <role-filter v-model="roles" :roles="[UserRole.OrganizationAdmin, UserRole.BillingAdmin, UserRole.CourseAdmin, UserRole.CoursewareAdmin,UserRole.OrganizationInstructor, UserRole.OrganizationStudent, UserRole.OrganizationTA]" data-testing="organization-users-role-filter"/>
                </table-filter-section-item>
            </table-filter-section>
       </template>
       <template #table>
            <cr-table :items="page?.items" :headers="headers" actionable @sort="onSort" :loading="isLoading" @suggestedNumberOfItems="onSuggestedNumberOfItems" class="cr-pl6 cr-pr6 cr-pt6 cr-pb2" data-testing="organization-users-table">
                <template v-slot:identityProvider="{value}">
                    <cr-identity-provider-icon :provider="value" />
                </template>
                <template v-slot:billingadmin="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:courseadmin="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:organizationadmin="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:organizationinstructor="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:organizationta="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:organizationstudent="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:action="{item}">
                    <cr-table-action-item v-if="item.isUser" :item="item" :to="{ name: Route.Profile.name, params: { userId: item.id }}" icon="bi-person-vcard" data-testing="organization-users-table-view-profile-action">
                        {{ t('ORGANIZATION_USERS_VIEW_PROFILE_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && !item.isAnonymous && (canUpdateOrganizationUser(organizationId) || canUpdateOrganizationUserRoles(organizationId))" :item="item" @click="onEditUserActionItemClicked" icon="bi-pencil" data-testing="organization-users-table-edit-user-action">
                        {{ t('ORGANIZATION_USERS_EDIT_USER_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && canImpersonate('', organizationId)" :item="item" @click="onImpersonateActionItemClicked" icon="bi-person-badge-fill" data-testing="organization-users-table-impersonate-action">
                        {{ t('ORGANIZATION_USERS_IMPERSONATE_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && canDeleteOrganizationUsers(organizationId)" :item="item" @click="onDeleteUserActionItemClicked" icon="bi-trash3" data-testing="organization-users-table-delete-user-action">
                        {{ t('ORGANIZATION_USERS_DELETE_USER_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && item.isPendingApproval && canApproveInvitations" :item="item" @click="onApproveAccountClicked" icon="bi-check" data-testing="organization-users-table-approve-invitation-action">
                        {{ t('ORGANIZATION_USERS_APPROVE_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && item.isPendingApproval && canApproveInvitations" :item="item" @click="onRejectAccountClicked" icon="bi-x" data-testing="organization-users-table-reject-invitation-action">
                        {{ t('ORGANIZATION_USERS_REJECT_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && !item.isPendingApproval && canInviteOrganizationUsers(organizationId)" :item="item" @click="onReInviteActionItemClicked" icon="bi-envelope" data-testing="organization-users-table-resend-invitation-action">
                        {{ t('ORGANIZATION_USERS_RESEND_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && canDeleteOrganizationInvitation(organizationId)" :item="item" @click="onDeleteInvitationActionItemClicked" icon="bi-trash3" data-testing="organization-users-table-delete-invitation-action">
                        {{ t('ORGANIZATION_USERS_DELETE_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && canInviteOrganizationUsers(organizationId)" :item="item" @click="onEditInvitationActionItemClicked" icon="bi-pencil" data-testing="organization-users-table-edit-invitation-action">
                        {{ t('ORGANIZATION_USERS_EDIT_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                </template>
            </cr-table>
            <account-approved-dialog v-model="showAccountApprovedDialog" @confirm="onAccountApprovedCompletedConfirm" />
            <account-rejected-dialog v-model="showAccountRejectedDialog" @confirm="onAccountRejectedCompletedConfirm" />
       </template>

       <template #pagination>
           <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading" data-testing="organization-users-table-pagination"/>
       </template>

       <template #controls>
            <table-control-item v-if="canInviteOrganizationUsers(organizationId)">
                <cr-button :to="inviteUserTo" outlined data-testing="organization-users-invite-users-button">
                        <cr-icon>bi-person-plus-fill</cr-icon>
                        {{ t('ORGANIZATION_USERS_INVITE_USERS') }}
                </cr-button>
            </table-control-item>
            <table-control-item v-if="canViewOrganizationUsers(organizationId)">
                <cr-button :disabled="isExportProcessing" @click="onExportUsersClicked" outlined data-testing="organization-users-export-users-button">
                        <cr-icon>bi-download</cr-icon>
                        {{ t('ORGANIZATION_USERS_EXPORT_USERS') }}
                </cr-button>
            </table-control-item>
            <delete-organization-user-dialog v-model="showDeleteUserDialog" :user="selectedItem" :organization-id="organizationId" @confirm="onDialogConfirmed"/>
            <delete-organization-invitation-dialog v-model="showDeleteInvitationDialog" :invitation="selectedItem" @confirm="onDialogConfirmed"/>
            <resend-organization-invitation-dialog v-model="showReinviteDialog" :invitation="selectedItem" @confirm="onDialogConfirmed"/>
            <edit-organization-invitation-dialog v-model="showEditInvitationDialog" :invitation="selectedItem" :organization-id="organizationId" @confirm="onDialogConfirmed"/>
            <edit-organization-user-dialog v-model="showEditUserDialog" :user="selectedItem" :organization-id="organizationId" @confirm="onDialogConfirmed"/>

       </template>
   </table-layout>
</template>


<script setup lang="ts">
import { IApiPageResponse, SortOrder } from '@cyber-range/cyber-range-api-client';
import { OrganizationUserFilter, OrganizationUserSortBy, UserRole, OrganizationUserTargetType } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem, ITableHeaderItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { onMounted, ref, computed, toRaw, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import { useApiClientStore } from '../../stores/apiClientStore';
import TableLayout from '../layouts/TableLayout.vue';
import TableFilterSection from '../layouts/sections/TableFilterSection.vue'
import TableFilterSectionItem from '../layouts/sections/TableFilterSectionItem.vue'
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useQueryFilter } from '../../composables/useQueryFilter';
import RoleFilter from '../filters/RoleFilter.vue';
import { useUserStore } from '../../stores/userStore';
import OrganizationFilter from '../filters/OrganizationFilter.vue';
import { useOrganizationStore } from '../../stores/organizationStore';
import { RouteLocationRaw, useRouter } from 'vue-router';
import Config from '../../config';
import { useEnum } from '../../composables/useEnum';
import IOrganizationUserRoleView from '../../interfaces/iOrganizationUserRoleView';
import OrganizationUserRoleView from '../../entities/organizationUserRoleView';
import DeleteOrganizationUserDialog from './dialogs/DeleteOrganizationUserDialog.vue';
import DeleteOrganizationInvitationDialog from './dialogs/DeleteOrganizationInvitationDialog.vue';
import ResendOrganizationInvitationDialog from './dialogs/ResendOrganizationInvitationDialog.vue';
import EditOrganizationInvitationDialog from './dialogs/EditOrganizationInvitationDialog.vue';
import EditOrganizationUserDialog from './dialogs/EditOrganizationUserDialog.vue';
import { useAuthenticationStore } from '../../stores/authenticationStore';
import TableControlItem from '../layouts/sections/TableControlItem.vue';
import {IOrganizationUsersPageFilter} from '../../interfaces/iOrganizationUsersPageFilter';
import {OrganizationUsersPageFilter} from '../../entities/OrganizationUsersPageFilter';
import {stringify} from 'csv-stringify/sync'
import { useDownload } from '../../composables/useDownload';
import { useAccountApprovals } from '../../composables/useAccountApprovals';
import AccountApprovedDialog from '../accountApprovals/dialogs/AccountApprovedDialog.vue';
import AccountRejectedDialog from '../accountApprovals/dialogs/AccountRejectedDialog.vue';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canInviteOrganizationUsers, canViewOrganizationUsers, canDeleteOrganizationUsers, canDeleteOrganizationInvitation, canUpdateOrganizationUser, canUpdateOrganizationUserRoles, canImpersonate} = useAuthorizationStore();
const { fetchOrganizations, fetchOrganizationNameAndLogo } = useOrganizationStore();
const { organizations } = storeToRefs(useOrganizationStore());

const allowedOrganizations = computed(() => organizations.value.filter(org => canViewOrganizationUsers(org.id)));

const router = useRouter();
const onOrganizationUsersPage = router.currentRoute.value.name === Route.OrganizationUsers.name;
const breadcrumbs = computed(()=> {
    if (onOrganizationUsersPage)
    {
        return [
            new BreadcrumbItem(Route.Organizations),
            new BreadcrumbItem({...Route.Organization, text: selectedOrganization.value?.name, params: {organizationId: props.organizationId}}),
            new BreadcrumbItem({...Route.OrganizationUsers, params: {organizationId: props.organizationId}})
        ]
    }
    else
    {
        return [
            new BreadcrumbItem(Route.Users)
        ]
    }
});

const inviteUserTo = computed<RouteLocationRaw>(()=> {
    return onOrganizationUsersPage
        ? { name: Route.InviteOrganizationUser.name, params: {organizationId: organizationId.value} }
        : { name: Route.InviteUser.name, state: { organizationId: organizationId.value } };
})

const props = defineProps<
{
   organizationId?:string
}>();

const selectedItem = ref<IOrganizationUserRoleView>();
const showDeleteUserDialog = ref<boolean>(false);
const showReinviteDialog = ref<boolean>(false);
const showDeleteInvitationDialog = ref<boolean>(false);
const showEditInvitationDialog = ref<boolean>(false);
const showEditUserDialog = ref<boolean>(false);
 

const page = ref<IApiPageResponse<IOrganizationUserRoleView>>();

const headerName = (enumKey: OrganizationUserSortBy) => useEnum().toDisplayEnumName(OrganizationUserSortBy, enumKey);
const headers = [
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.Name), key: 'name', sortable: true}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.Email), key: 'email', sortable: true}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.BillingAdmin), key: 'billingadmin', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.CourseAdmin), key: 'courseadmin', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.OrganizationAdmin), key: 'organizationadmin', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.Instructor), key: 'organizationinstructor', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.TA), key: 'organizationta', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.Student), key: 'organizationstudent', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.IdentityProvider), key: 'identityProvider', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.LastLoginWith), key: 'lastLoginWith', sortable: true}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.BusinessUnit), key: 'displayedBusinessUnit', sortable: true}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.LastLoginDate), key: 'lastLogin', sortable: true}),
];


let filter: IOrganizationUsersPageFilter;
const sortOrder = ref<SortOrder>();
const sortBy = ref<OrganizationUserSortBy>();
const roles = ref<string[]>([]);
const suggestedNumberOfItems = ref<number>(Config.DEFAULT_TILE_LISTING_PAGE_SIZE);
const organizationId = ref<string>(props.organizationId || '');
const selectedOrganization = computed(() => allowedOrganizations.value.filter(org => org.id === organizationId.value)[0]);
let mountComplete = false;

const initializeFilter = () =>
{
    filter = useQueryFilter(OrganizationUsersPageFilter, {limit:suggestedNumberOfItems.value}, {arrayProperties: ['roles']});

    if (filter.sortBy)
    {
        sortBy.value = filter.sortBy;
        sortOrder.value = filter.sortOrder;

        // BusinessUnit gets transformed by OrganizationUserRoleView so it has a different key
        const headerKeyToFind = filter.sortBy === OrganizationUserSortBy.BusinessUnit ? 'displayedbusinessUnit' : filter.sortBy;
        let sortedHeader = headers.find(header => header.key.toLowerCase() === headerKeyToFind);
        
        if(sortedHeader)
        {
            sortedHeader.sortOrder = filter.sortOrder;
        }
    }

    roles.value = filter.roles
}

onMounted(async ()=>
{
    initializeFilter();
    await fetchOrganizations();
    if (onOrganizationUsersPage)
    {
        await fetchOrganizationNameAndLogo(props.organizationId);
    }
    else
    {
        await fetchOrganizationNameAndLogo(organizations.value);
        if (!organizationId.value)
        {
            organizationId.value = filter.organizationId || allowedOrganizations.value[0]?.id;
        }
    }

    await refresh();
    mountComplete = true;
});

const reload = async (data:Partial<IOrganizationUsersPageFilter>) =>
{
    Object.assign(filter, {...data, sortBy: sortBy.value, sortOrder: sortOrder.value, roles: roles.value, organizationId: organizationId.value});
}

const refresh = async (data:Partial<IOrganizationUsersPageFilter> = {}) =>
{
    const orgUsersPage = await useUserStore().listOrganizationUsers(organizationId.value, new OrganizationUserFilter({...toRaw(filter), sortBy: sortBy.value, sortOrder: sortOrder.value, roles: roles.value, ...data}));
    
    orgUsersPage.items = orgUsersPage?.items?.map(orgUser => new OrganizationUserRoleView(orgUser));
    page.value = orgUsersPage as IApiPageResponse<IOrganizationUserRoleView>;
    return page.value;
}

let orgIdFirstLoad = true;

watch(organizationId, async ()=>
{
    // Dont react to the changes from onMounted, but any time after that
    if (!orgIdFirstLoad)
    {
        await refresh({ token: '' });
    }
    orgIdFirstLoad = false;
});

watch(roles, async () =>
{
    if (mountComplete)
    {
        await refresh();
    }
});

const onSuggestedNumberOfItems = (n:number) =>
{
    suggestedNumberOfItems.value = n;
}

const onLoadPreviousPage = () =>
{
    reload({token: page.value?.prevPageToken || ''});
}

const onLoadNextPage = () =>
{
    reload({token: page.value?.nextPageToken || ''});
}

const onLoadFirstPage = () =>
{
    reload({token: ''});
}

const onSort = async (header:ITableHeaderItem) =>
{
    sortOrder.value = header.sortOrder as SortOrder;
    const sortKey = header.text.replaceAll(" ", "");
    sortBy.value = sortKey in OrganizationUserSortBy ? OrganizationUserSortBy[sortKey as keyof typeof OrganizationUserSortBy] : sortKey.toLowerCase() as OrganizationUserSortBy;
    await refresh({token: ""});
}

const onDeleteUserActionItemClicked = (item:IOrganizationUserRoleView) =>
{
    selectedItem.value = item;
    showDeleteUserDialog.value = true;
}

const onReInviteActionItemClicked = (item:IOrganizationUserRoleView) =>
{
    selectedItem.value = item;
    showReinviteDialog.value = true;
}

const onDeleteInvitationActionItemClicked = (item:IOrganizationUserRoleView) =>
{
    selectedItem.value = item;
    showDeleteInvitationDialog.value = true;
}

const onEditInvitationActionItemClicked = (item:IOrganizationUserRoleView) =>
{
    selectedItem.value = item;
    showEditInvitationDialog.value = true;
}

const onEditUserActionItemClicked = (item:IOrganizationUserRoleView) =>
{
    selectedItem.value = item;
    showEditUserDialog.value = true;
}

const onImpersonateActionItemClicked = async (item:IOrganizationUserRoleView) =>
{
    if ((await useAuthenticationStore().impersonate(item.id)))
    {
        router.push(Route.Home);
    }
}

const onDialogConfirmed = async () =>
{
    await refresh();
}

const isExportProcessing = ref(false);
async function onExportUsersClicked()
{
    isExportProcessing.value = true
    try
    {
        const users = (await useUserStore().listAllOrganizationUsers(organizationId.value, new OrganizationUserFilter({...toRaw(filter), sortBy: sortBy.value, sortOrder: sortOrder.value, roles: roles.value }))).map(orgUser => new OrganizationUserRoleView(orgUser));
        const data = stringify(users, {
            columns: headers.map(h => ({ key: h.key, header: h.text })),
            header: true,
            cast: {boolean: (v: boolean) => v.toString()},
        });
        useDownload().downloadFromCsv(data, 'exported_users');
    }
    finally
    {
        isExportProcessing.value = false;
    }
}

const {
    canApproveInvitations,
    showAccountApprovedDialog,
    onApproveAccountClicked,
    showAccountRejectedDialog,
    onRejectAccountClicked
} = useAccountApprovals(organizationId);
const onAccountApprovedCompletedConfirm = refresh;
const onAccountRejectedCompletedConfirm = refresh;
</script>