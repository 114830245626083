<template>
    <form-section :label="t('EXERCISE_GROUP_FORM_SECTION_TITLE_AVAILABILITY')" class="cr-mt5">
        <cr-row>
            <cr-col>
                <cr-date-picker :label="t('EXERCISE_GROUP_FORM_START_LABEL')" v-model="startDate" :min="courseStartDate" :max="endDate" required :disabled="disabled"/>
            </cr-col>
            <cr-col>
                <cr-date-picker :label="t('EXERCISE_GROUP_FORM_END_LABEL')" v-model="endDate" :min="startDate" :max="courseEndDate" required :disabled="disabled"/>
            </cr-col>
        </cr-row>
    </form-section>
</template>

<script setup lang="ts">
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import { computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCalendar } from '../../composables/useCalendar';
import FormSection from '../layouts/sections/FormSection.vue';

const { t } = useI18n();

const props = defineProps<
{
    course?: ICourse;
    startTime?: string;
    endTime?: string;
    disabled?: boolean;
}>();
const emit = defineEmits<{
    (name: 'update:startTime', value: string|undefined): void,
    (name: 'update:endTime', value: string|undefined): void
}>()

const calendarHelpers = useCalendar();

const courseStartDate = computed(() => props.course?.startTime?.slice(0,10))
const courseEndDate = computed(() => props.course?.endTime?.slice(0,10))
const startDate = computed({
    get: () => props.startTime?.slice(0,10),
    set(value:string|undefined) {
        value &&= calendarHelpers.toStartOfDayISOString(value);
        emit('update:startTime', value);
    }
})
const endDate = computed({
    get: () => props.endTime?.slice(0,10),
    set(value:string|undefined) {
        value &&= calendarHelpers.toEndOfDayISOString(value);
        emit('update:endTime', value);
    }
})

watchEffect(async () =>
{
    const today = calendarHelpers.toYYYYMMDD(new Date());
    startDate.value ||= (!courseStartDate.value || today > courseStartDate.value) ? today : courseStartDate.value;
    endDate.value ||= courseEndDate.value || today;
});
</script>
