<template>
    <cr-table :items="page.items" :headers="tableHeaders" @sort="onSort" @suggested-number-of-items="onSuggestedNumberOfItems" class="cr-pl6 cr-pr6 cr-pt6 cr-pb2 cr-mb5">
        <template #name="{value, item}">
            <router-link v-if="item.to" :to="item.to">
                {{ value }}
            </router-link>
            <template v-else>
                {{ value }}
            </template>
        </template>
    </cr-table>
    <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" />
</template>

<style scoped>
:deep(.cr-table-cell a) {
    color: var(--cr-primary-link);
}
</style>

<script setup lang="ts">
import { SortOrder } from '@cyber-range/cyber-range-api-client';
import { IUser, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { useArtificialPaging } from '../../composables/useArtificiailPaging';
import { useEnum } from '../../composables/useEnum';
import Route from '../../routers/route';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useCourseStore } from '../../stores/courseStore';
import { useOrganizationStore } from '../../stores/organizationStore';

const authorizationStore = useAuthorizationStore();
const organizationStore = useOrganizationStore();
const courseStore = useCourseStore();
const { toDisplayEnumName } = useEnum()

const props = defineProps<{
    user: IUser
}>()

const tableHeaders = [
    new TableHeaderItem({ key: 'type', text: 'Type', sortable: true, sortOrder: SortOrder.Desc }),
    new TableHeaderItem({ key: 'name', text: 'Name', sortable: true }),
    new TableHeaderItem({ key: 'roles', text: 'Roles', formatter: (roles: Set<String>) => Array.from(roles.values()).sort().join(', ') }),
]

const memberships = computed(() =>
{
    const membershipsMap = new Map<string, { type: string, name: string, roles: Set<String>, to: RouteLocationRaw|undefined }>()
    for (const { organizationId: id, role } of props.user.organizations || [])
    {
        if (!membershipsMap.has(id))
        {
            const organization = organizationStore.organizations.find(o => o.id === id);
            membershipsMap.set(id, {
                type: 'organization',
                name: organization?.name || id,
                roles: new Set(),
                to: organization && authorizationStore.canManageOrganization(id) ? { name: Route.Organization.name, params: { organizationId: id } } : undefined,
            });
        }
        membershipsMap.get(id)!.roles.add(toDisplayEnumName(UserRole, role as UserRole));
    }
    for (const { id, role } of props.user.courses || [])
    {
        if (!membershipsMap.has(id))
        {
            membershipsMap.set(id, {
                type: 'course',
                name: courseStore.courses.find(c => c.id === id)?.name ?? id,
                roles: new Set(),
                to: { name: Route.Course.name, params: { courseId: id } },
            });
        }
        membershipsMap.get(id)!.roles.add(toDisplayEnumName(UserRole, role as UserRole));
    }

    return Array.from(membershipsMap.values());
});

const {
    onLoadFirstPage,
    onLoadNextPage,
    onLoadPreviousPage,
    onSuggestedNumberOfItems,
    onSort,
    page
} = useArtificialPaging(memberships);

onMounted(async () =>
{
    await Promise.all([
        useOrganizationStore().fetchOrganizations(),
        useCourseStore().fetchAllCourses()
    ]);
})
</script>
