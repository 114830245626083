<template>
    <table-layout :title="t('AUDIT_LOGS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
        <template #filter>
            <table-filter-section>
                <table-filter-section-item>
                    <between-date-filter v-model:from-date="filter.from" v-model:to-date="filter.to" :label="t('AUDIT_LOGS_FILTER_DATE_LABEL')" data-testing="audit-logs-date-filter"/>
                </table-filter-section-item>
                <table-filter-section-item>
                    <organization-filter v-model="filter.organizationId" :organizations="organizationStore.organizations" data-testing="audit-logs-organization-filter"/>
                </table-filter-section-item>
                <table-filter-section-item>
                    <autocomplete-filter :label="t('AUDIT_LOGS_FILTER_STATUS_LABEL')" icon="bi-activity" v-model="filter.status" :items="AuditEventStatus" :disabled="isLoading"data-testing="audit-logs-status-filter" />
                </table-filter-section-item>
                <table-filter-section-item>
                    <text-filter :label="t('AUDIT_LOGS_FILTER_USER_LABEL')" icon="bi-people" v-model="filter.userId" :disabled="isLoading" data-testing="audit-logs-user-filter"/>
                </table-filter-section-item>
                <table-filter-section-item>
                    <text-filter :label="t('AUDIT_LOGS_FILTER_OBJECT_ID_LABEL')" icon="bi-box" v-model="filter.objectId" :disabled="isLoading" data-testing="audit-logs-object-id-filter"/>
                </table-filter-section-item>
            </table-filter-section>
        </template>

        <template #table>
            <cr-table :items="page?.items" :headers="headers" :loading="isLoading" @suggestedNumberOfItems="onSuggestedNumberOfItems" class="cr-pl6 cr-pr6 cr-pt6 cr-pb2" data-testing="audit-logs-table">
                <template #organizationId="{value, item}">
                    <router-link v-if="value" :to="{ query: { ...filter, token: '' , organizationId: item.organizationId }}">
                        {{ value }}
                    </router-link>
                </template>

                <template #user="{value, item}">
                    <template v-if="item.impersonator">
                        <router-link :to="{ query: { ...filter, token: '' , userId: item.impersonator.id}}">
                            {{ item.impersonator.name}}
                        </router-link>
                        <br/>{{ t('AUDIT_PAGE_ON_BEHALF_OF_USER') }}
                    </template>
                    <router-link :to="{ query: { ...filter, token: '' , userId: value.id}}">
                        {{ value.name }}
                    </router-link>
                </template>

                <template #message="{value}">
                    <audit-message :message="value" :filter="filter"/>
                </template>

                <template #statusCode="{value}">
                    <cr-chip v-if="value < 400" :message="t('AUDIT_LOGS_STATUS_OK')" background-color="cr-chip-success-background-color"/>
                    <cr-chip v-else :message="t('AUDIT_LOGS_STATUS_FAILED')" background-color="cr-chip-error-background-color"/>
                </template>

                <template #details="{value}">
                    <dl v-if="value">
                        <template v-for="[term, detail], index in Object.entries(value)">
                            <br v-if="index !== 0"/>
                            <dt><b>{{ term }}:</b></dt> <dd>{{ detail }}</dd>
                        </template>
                    </dl>
                </template>
            </cr-table>
        </template>

        <template #pagination>
            <cr-pagination :previous="!!page?.prevPageToken" :previous-text="t('AUDIT_LOGS_PAGINATION_PREVIOUS')" :next="!!page?.nextPageToken" :next-text="t('AUDIT_LOGS_PAGINATION_NEXT')" :first="false" @previous="onNewerClicked" @next="onLoadNextPage" :loading="isLoading" data-testing="audit-logs-table-pagination"/>
        </template>
   </table-layout>
</template>

<style scoped>
:deep(.cr-table-cell a) {
    color: var(--cr-primary-link);
}
dt, dd {
    display: inline-block;
}
</style>

<script setup lang="ts">
import { AuditEventFilter, AuditEventStatus, IAuditEvent } from '@cyber-range/cyber-range-api-audit-client';
import { ApiClientError } from '@cyber-range/cyber-range-api-client';
import { BreadcrumbItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useCalendar } from '../../composables/useCalendar';
import { useQueryFilter } from '../../composables/useQueryFilter';
import { useTableLayoutPagination } from '../../composables/useTableLayoutPagination';
import Route from '../../routers/route';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useAuditStore } from '../../stores/auditStore';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useErrorStore } from '../../stores/errorStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import AutocompleteFilter from '../filters/AutocompleteFilter.vue';
import BetweenDateFilter from '../filters/BetweenDateFilter.vue';
import OrganizationFilter from '../filters/OrganizationFilter.vue';
import TextFilter from '../filters/TextFilter.vue';
import TableLayout from '../layouts/TableLayout.vue';
import TableFilterSection from '../layouts/sections/TableFilterSection.vue';
import TableFilterSectionItem from '../layouts/sections/TableFilterSectionItem.vue';
import AuditMessage from './auditMessage';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const breadcrumbs = [new BreadcrumbItem(Route.Logs)];
const calendarHelpers = useCalendar();
const organizationStore = useOrganizationStore();
const route = useRoute()

const {
    filter,
    page,
    onSuggestedNumberOfItems,
    onLoadPreviousPage,
    onLoadFirstPage,
    onLoadNextPage,
} = useTableLayoutPagination(useQueryFilter(AuditEventFilter), useAuditStore().list, { clearTokenKeys: ['courseId','organizationId','objectId','userId']});

async function onNewerClicked()
{
    let results = await useAuditStore().list({ ...filter, token: page.value?.prevPageToken });
    if (results.items.length === 0)
    {
        // Don't do anything if there are no newer items
    }
    // If number of previous items is less than the expected page size, then assume we're on the first page and reload it
    else if (results.items.length < filter.limit!)
    {
        await onLoadFirstPage();
    }
    // Otherwise do normal load previous page behavior with reloading and previous page token in url
    else
    {
        await onLoadPreviousPage();
    }
}

const headers = computed(() => 
{
    const eventIdHeader = useAuthorizationStore().canViewAnyAuditEvent()
        ? [new TableHeaderItem({ text: t('AUDIT_LOGS_EVENT_ID'), key: 'event', formatter: (event: IAuditEvent['event']) => event.id })]
        : [];

    return [
        new TableHeaderItem({ text: t('AUDIT_LOGS_TIMESTAMP'), key: 'timestamp', formatter: calendarHelpers.toHuman }),
        new TableHeaderItem({ text: t('AUDIT_LOGS_ORGANIZATION'), key: 'organizationId', formatter: (oid) => organizationStore.organizations.find(o => o.id === oid)?.name || oid }),
        new TableHeaderItem({ text: t('AUDIT_LOGS_USER'), key: 'user' }),
        new TableHeaderItem({ text: t('AUDIT_LOGS_ACTION'), key: 'message' }),
        new TableHeaderItem({ text: t('AUDIT_LOGS_RESULT'), key: 'statusCode' }),
        ...eventIdHeader,
        new TableHeaderItem({ text: t('AUDIT_LOGS_DETAILS'), key: 'details' }),
    ];
});

onMounted(async () =>
{
    await organizationStore.fetchOrganizations();
});
</script>
