<template>
    <!-- Start -->
    <read-control-item :no-wrap="compact">
        <cr-button v-if="compact" :title="t('EXERCISE_START_BUTTON_TITLE')" :label="startLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(!canStartExercise)" :disabled="!canStartExercise" @click="onStartExerciseClicked" data-testing="exercise-group-exercise-control-start">
            <cr-icon>bi-power</cr-icon>
        </cr-button>
        <hint v-else :hint-if="showStartHint" :messages="startHintMessages" :hint-body-id="startHintId" >
            <cr-button :title="t('EXERCISE_START_BUTTON_TITLE')" :label="startLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(!canStartExercise)" :disabled="!canStartExercise" @click="onStartExerciseClicked" :aria-descibedby="startHintId" data-testing="exercise-group-exercise-control-start">
                <cr-icon>bi-power</cr-icon>
                {{ t('EXERCISE_GROUP_START_MY_EXERCISE_BUTTON') }}
            </cr-button>
        </hint>
    </read-control-item>

    <!-- Join -->
    <read-control-item :no-wrap="compact" v-if="exercise.accessPoints?.length > 1">        
        <cr-dropdown v-if="compact" :class="{ compact }">
            <cr-dropdown-toggle :title="t('EXERCISE_JOIN_BUTTON_TITLE')" :label="joinLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(!canJoinExercise)" :disabled="!canJoinExercise" showToggleIcon :class="{ 'cr-mb2': !compact }" data-testing="exercise-group-exercise-control-join-list-toggle">
                <cr-icon>bi-play</cr-icon>
            </cr-dropdown-toggle>
            <cr-dropdown-list>
                <cr-dropdown-list-item v-for="accessPoint in exercise.accessPoints" role="link" @click="onJoinAccessPointClicked(accessPoint.id)" data-testing="exercise-group-exercise-control-join-list-item">
                    {{ accessPoint.name }}
                </cr-dropdown-list-item>
            </cr-dropdown-list>
        </cr-dropdown>
        <hint v-else :hint-if="showJoinHint" :messages="joinHintMessages" :hint-body-id="joinHintId" >
            <cr-dropdown :class="{ compact }">
                <cr-dropdown-toggle :title="t('EXERCISE_JOIN_BUTTON_TITLE')" @click="onMultiVmDropDownMenuClicked" :label="joinLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(!canJoinExercise)" :disabled="!canJoinExercise" showToggleIcon data-testing="exercise-group-exercise-control-join-list-toggle">
                    <cr-icon>bi-play</cr-icon>
                    {{ t('EXERCISE_GROUP_JOIN_MY_EXERCISE_BUTTON') }}
                </cr-dropdown-toggle>
                <cr-dropdown-list>
                    <cr-dropdown-list-item v-for="accessPoint in exercise.accessPoints" role="link" @click="onJoinAccessPointClicked(accessPoint.id)" data-testing="exercise-group-exercise-control-join-list-item">
                        {{ accessPoint.name }}
                    </cr-dropdown-list-item>
                </cr-dropdown-list>
            </cr-dropdown>
        </hint>
    </read-control-item>
    <read-control-item :no-wrap="compact" v-else>
        <cr-button v-if="compact" :title="t('EXERCISE_JOIN_BUTTON_TITLE')" :label="joinLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(!canJoinExercise)" :disabled="!canJoinExercise" role="link" @click="onJoinAccessPointClicked(exercise.accessPoints?.[0]?.id)" data-testing="exercise-group-exercise-control-join">
            <cr-icon>bi-play</cr-icon>
        </cr-button>
        <hint v-else :hint-if="showJoinHint" :messages="joinHintMessages" :hint-body-id="joinHintId" >
            <cr-button :title="t('EXERCISE_JOIN_BUTTON_TITLE')" :label="joinLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(!canJoinExercise)" :disabled="!canJoinExercise" role="link" @click="onJoinAccessPointClicked(exercise.accessPoints?.[0]?.id)" data-testing="exercise-group-exercise-control-join">
                <cr-icon>bi-play</cr-icon>
                {{ t('EXERCISE_GROUP_JOIN_MY_EXERCISE_BUTTON') }}
            </cr-button>
        </hint>
    </read-control-item>

    <!-- Stop -->
    <read-control-item :no-wrap="compact">
        <cr-button :title="t('EXERCISE_STOP_BUTTON_TITLE')" :label="stopLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(!canStopExercise)" :disabled="!canStopExercise" @click="onStopExerciseClicked" data-testing="exercise-group-exercise-control-stop">
            <cr-icon>bi-stop</cr-icon>
            <template v-if="!compact" >{{ t('EXERCISE_GROUP_STOP_MY_EXERCISE_BUTTON') }}</template>
        </cr-button>
    </read-control-item>

    <!-- Restart -->
    <read-control-item :no-wrap="compact">
        <cr-button :title="t('EXERCISE_RESTART_BUTTON_TITLE')" :label="restartLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(!canRestartExercise)" :disabled="!canRestartExercise" @click="onRestartExerciseClicked" data-testing="exercise-group-exercise-control-restart">
            <cr-icon>bi-arrow-counterclockwise</cr-icon>
            <template v-if="!compact" >{{ t('EXERCISE_GROUP_RESTART_MY_EXERCISE_BUTTON') }}</template>
        </cr-button>
    </read-control-item>

    <!-- Reset -->
    <read-control-item :no-wrap="compact">
        <cr-button :title="t('EXERCISE_RESET_BUTTON_TITLE')" :label="resetLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(!canResetExercise)" :disabled="!canResetExercise" @click="onResetExerciseClicked" data-testing="exercise-group-exercise-control-reset">
            <cr-icon>bi-skip-start</cr-icon>
            <template v-if="!compact" >{{ t('EXERCISE_GROUP_RESET_MY_EXERCISE_BUTTON') }}</template>
        </cr-button>
    </read-control-item>
    <reset-exercise-confirm-dialog v-model="showConfirmResetExerciseDialog" @confirm="onResetExerciseConfirmed"/>

    <!-- Copy -->
    <read-control-item :no-wrap="compact">
        <cr-button :title="t('EXERCISE_COPY_BUTTON_TITLE')" :label="copyLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(!canCopyExercise)" :disabled="!canCopyExercise" @click="onCopyExerciseClicked" data-testing="exercise-group-exercise-control-copy">
            <cr-icon>bi-files</cr-icon>
            <template v-if="!compact" >{{ t('EXERCISE_GROUP_COPY_MY_EXERCISE_BUTTON') }}</template>
        </cr-button>
    </read-control-item>
    <copy-exercise-dialog v-model="showCopyExerciseDialog" @confirm="onCopyExerciseConfirmed"/>
    <environment-copying-dialog v-model="exerciseCopyInitiated" />

    <!-- Credentials -->
    <template v-if="hasCredentials">
        <read-control-item :no-wrap="compact">
            <cr-button :title="t('EXERCISE_CREDENTIALS_BUTTON_TITLE')" :label="credentialsLabel" v-bind="buttonDisplayAttributes" :text-color="getTextColor(false)" @click="onShowCredentialsClicked" data-testing="exercise-group-exercise-control-view-credentials">
                <cr-icon>bi-incognito</cr-icon>
                <template v-if="!compact" >{{ t('EXERCISE_GROUP_MY_EXERCISE_CREDENTIALS_BUTTON') }}</template>
            </cr-button>
        </read-control-item>
        <exercise-credentials-dialog v-model="showCredentialsDialog" :exercise="exercise"/>
    </template>
</template>

<style scoped>
.compact {
    display: inline;
}
</style>

<script setup lang="ts">
import { AccessPointFeatureName, ExerciseStatus, IExercise } from '@cyber-range/cyber-range-api-exercise-client';
import { computed, onBeforeUnmount, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useExerciseControls } from '../../composables/useExerciseControls';
import { useExerciseStore } from '../../stores/exerciseStore';
import { useTimerStore } from '../../stores/timerStore';
import CopyExerciseDialog from './dialogs/CopyExerciseDialog.vue';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';
import ExerciseCredentialsDialog from './dialogs/ExerciseCredentialsDialog.vue';
import ResetExerciseConfirmDialog from './dialogs/ResetExerciseConfirmDialog.vue';
import EnvironmentCopyingDialog from './dialogs/EnvironmentCopyingDialog.vue';
import Hint from '../hints/Hint.vue';
import { useUniqueId } from '../../composables/useUniqueId';

const props = defineProps<
{
    modelValue: IExercise;
    compact?: boolean;
    polling?: boolean
}>();
const emit = defineEmits<{
    (name: 'update:modelValue', exercise: IExercise): void,
    (name: 'loading', isLoading: boolean): void,
}>();
const exercise = computed<IExercise>({
    get: () => props.modelValue,
    set(exercise: IExercise) { emit('update:modelValue', exercise); }
});

const { t } = useI18n();
const exerciseStore = useExerciseStore();
const timerStore = useTimerStore();

const buttonDisplayAttributes = computed(() => props.compact 
    ? { text: true }
    : { outlined: true }
);
const getTextColor = (isDisabled: boolean) => (props.compact && !isDisabled) ? 'cr-primary' : undefined;

const showCopyExerciseDialog = ref(false);
const showConfirmResetExerciseDialog = ref(false);
const {
        canStartExercise,
        startExercise: onStartExercise,
        canJoinExercise,
        joinExercise: onJoinExercise,
        canStopExercise,
        stopExercise: onStopExerciseClicked,
        canResetExercise,
        resetExercise: onResetExerciseConfirmed,
        canRestartExercise,
        restartExercise: onRestartExerciseClicked,
        canCopyExercise,
        copyExercise: onCopyExerciseConfirmed,
        isLoading,
        exerciseCopyInitiated
} = useExerciseControls(exercise);

const onStartExerciseClicked = ()=>
{
    closeStartHint.value = true;
    onStartExercise()
}

const onJoinAccessPointClicked = (id:string)=>
{
    closeJoinHint.value = true;
    onJoinExercise(id);
}

const startLabel = computed(() => props.compact ? t('EXERCISE_START_BUTTON_LABEL', { name: exercise.value.ownerName }): undefined);
const joinLabel = computed(() => props.compact ? t('EXERCISE_JOIN_BUTTON_LABEL', { name: exercise.value.ownerName }): undefined);
const stopLabel = computed(() => props.compact ? t('EXERCISE_STOP_BUTTON_LABEL', { name: exercise.value.ownerName }): undefined);
const restartLabel = computed(() => props.compact ? t('EXERCISE_RESTART_BUTTON_LABEL', { name: exercise.value.ownerName }): undefined);
const resetLabel = computed(() => props.compact ? t('EXERCISE_RESET_BUTTON_LABEL', { name: exercise.value.ownerName }): undefined);
const copyLabel = computed(() => props.compact ? t('EXERCISE_COPY_BUTTON_LABEL', { name: exercise.value.ownerName }): undefined);

const onCopyExerciseClicked = () => showCopyExerciseDialog.value = true;
const onResetExerciseClicked = () => showConfirmResetExerciseDialog.value = true;

// Exercise Credentials
const showCredentialsDialog = ref(false);
const hasCredentials = computed<boolean>(() => exercise.value.accessPoints?.some(ap => ap.features?.some(f => f.name === AccessPointFeatureName.Username || f.name === AccessPointFeatureName.Password)));
const credentialsLabel = computed(() => props.compact ? t('EXERCISE_CREDENTIALS_BUTTON_LABEL', { name: exercise.value.ownerName }): undefined);
const onShowCredentialsClicked = () => showCredentialsDialog.value = true;

// Hints
const currentStatus = computed(()=>exercise?.value.status);
const closeStartHint = ref<boolean>(false);
const showStartHint = computed(()=>
{
    return [ExerciseStatus.STUB,ExerciseStatus.STOPPED].includes(currentStatus.value) && !exercise.value?.statistics?.lastStartedTimestamp && !closeStartHint.value && !!canStartExercise.value;
});
const startHintId = useUniqueId();
const startHintMessages = [t('EXERCISE_CONTROLS_START_HINT'),t('EXERCISE_CONTROLS_USE_START_BUTTON_HINT')];

const closeJoinHint = ref<boolean>(false);
const onMultiVmDropDownMenuClicked = ()=>
{
    closeJoinHint.value = true;
}
const showJoinHint = computed(()=>
{
    return currentStatus.value === ExerciseStatus.READY && !exercise.value?.statistics?.firstAccessTimestamp && !closeJoinHint.value && !!canJoinExercise.value;
});
const joinHintId = useUniqueId();
const joinHintMessages = [t('EXERCISE_CONTROLS_JOIN_READY_HINT'),t('EXERCISE_CONTROLS_JOIN_ENVIRONMENT_HINT'),t('EXERCISE_CONTROLS_USE_JOIN_BUTTON_HINT')];

// polling
watchEffect(() => emit('loading', isLoading.value ?? false));

let timerId: string = ''
watch(() => props.polling, () =>
{
    if (props.polling && !timerId)
    {
        timerId = timerStore.schedule(async () =>
        {
            exercise.value = await exerciseStore.getOne(props.modelValue.id, { background: true });
        }, undefined, 5000);
    }
    else if (!props.polling && timerId)
    {
        timerStore.unschedule(timerId);
    }
}, { immediate: true });

onBeforeUnmount(() =>
{
    timerStore.unschedule(timerId);
});
</script>
